<script>
import DOMPurify from 'dompurify';
import { toBase64 } from '@shared/services/Utils';

export default {
  head: {
    title: 'SEO (référencement naturel)',
  },
  form: {
    title: {
      label: 'Titre SEO (affiché sur Google, Bing, Facebook, LinkedIn...)',
      help: '50 – 60 caractères maximum recommandés par Google',
      type: 'text',
      column: 'is-12',
      inputAttrs: {
      },
    },
    description: {
      label: 'Description SEO (affichée sur Google, Bing, Facebook, LinkedIn...)',
      help: '155 – 160 caractères maximum recommandés par Google',
      type: 'textarea',
      column: 'is-12',
      inputAttrs: {
      },
    },
    image: {
      label: 'Image (affichée sur Facebook, LinkedIn, Twitter...)',
      type: 'upload',
      inputAttrs: { accept: 'image/*' },
      column: 'is-8',
    },
  },
  data() {
    return {
      isLoading: false,
      seo: {
        title: '',
        description: '',
        image: '',
      },
    };
  },
  computed: {
    authStore() {
      return this.$store.getters['auth/store'];
    },
  },
  created() {
    this.hydrateSEO();
    this.setPlaceholders();
  },
  methods: {
    hydrateSEO() {
      this.seo.title = this.authStore.seo_data.title;
      this.seo.description = this.authStore.seo_data.description;
      this.seo.image = this.authStore.seo_data.image;
    },
    setPlaceholders() {
      const title = this.authStore.name;
      const { description } = this.authStore;

      this.$options
        .form.title
        .inputAttrs
        .placeholder = title;
      this.$options
        .form.description
        .inputAttrs
        .placeholder = DOMPurify.sanitize(description, { USE_PROFILES: { html: false } });
    },
    handlePicture(file) {
      if (!file) {
        this.seo.image = null;
        return;
      }

      if (file.size > this.$constants.MAX_IMAGE_SIZE) {
        this.$buefy.dialog.alert(this.$t('messages.file_larger_than', {
          size: '5 Mo',
        }));
        return;
      }

      const loader = this.$buefy.loading.open({ container: this.$refs.form });
      toBase64(file)
        .then((base64) => (this.seo.image = base64))
        .finally(() => loader.close());
    },
    handle() {
      const seo = { ...this.seo };

      if (seo.image && seo.image.startsWith('http')) {
        seo.image = seo.image.replace(`${this.$env.apiResourcesURL}/`, '');
      }

      this.isLoading = true;
      this.$store.dispatch('auth/updateStoreSEO', seo)
        .then(() => this.$showMessage.success())
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div>
    <div class="columns">
      <div class="column is-4 content pr-10 is-small">
        <h2 class="is-size-5">
          Paramètres SEO
        </h2>
        <p>
          Configurez les metadonnées qui améliorent le
          référencement naturel de votre page d'accueil Teachizy.
        </p>
        <p>
          Ces données seront également affichées lors des partages sur
          <strong>Facebook</strong>, <strong>Twitter</strong>, <strong>LinkedIn</strong>...
        </p>
        <p>
          <strong>Ces champs doivent être modifiés avec précaution.</strong>
        </p>
        <p>
          Vous ne savez pas ce qu'est le SEO ? <br>
          <strong><a href="https://www.youtube.com/watch?v=szzjkQDfVVo" target="_blank" rel="noopener noreferrer">Regardez notre tutoriel sur le sujet</a>.</strong>
        </p>
      </div>

      <div class="column">
        <form
          ref="form"
          class="box"
          @submit.prevent="dataIsValid(isLoading) && handle()"
        >
          <div class="columns is-multiline">
            <div
              v-for="(field, k) in $options.form"
              :key="k"
              class="column"
              :class="field.column">
              <BaseField
                v-if="k == 'image'"
                :field="field"
                :value="seo[k]"
                @input="handlePicture"
              >
                <template v-if="!seo[k]" #notification>
                  Si aucune image n'est ajoutée,
                  l'image utilisée pour les réseaux sociaux
                  sera votre logo.
                </template>
              </BaseField>
              <BaseField
                v-else
                v-model="seo[k]"
                :field="field"
              />
            </div>
          </div>

          <b-field class="has-text-right">
            <b-button type="is-primary" native-type="submit" :loading="isLoading">
              Mettre à jour
            </b-button>
          </b-field>
        </form>
      </div>
    </div>
  </div>
</template>
